.nav-link {
    text-decoration: none;
    width: 100%;
    color: inherit;
    padding: 0.5rem 1rem;
    margin: 0.25rem 0.875rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .list-icon {
        min-width: 0;
        margin-right: 0.5rem;
    }

    &.active {
        color: #fff;
        box-shadow: rgba(115, 103, 240, 0.48) 0px 2px 6px;
        background: linear-gradient(72.47deg, rgb(115, 103, 240) 22.16%, rgba(115, 103, 240, 0.7) 76.47%);
        border-radius: 6px;

        .list-icon {
            color: #fff;
        }
    }

    .list-text {
        margin: 0;
        white-space: nowrap;
    }
}

.nav-close {
    .list-icon {
        margin-right: 0;
    }

    .list-text {
        width: 0;
        opacity: 0;
    }
}