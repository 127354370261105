.tableComponent {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    // box-shadow: rgba(36, 34, 43, 0.03) 0px 3px 9px 4px, rgb(51 48 60 / 2%) 0px 8px 9px 2px, rgb(51 48 60 / 1%) 0px 1px 6px 1px;
    border-radius: 7px;

    // background-color: #fefdfd;
    .light-shadow {
        box-shadow: rgba(36, 34, 43, 0.03) 0px 3px 9px 4px, rgb(51 48 60 / 2%) 0px 8px 9px 2px,
            rgb(51 48 60 / 1%) 0px 1px 6px 1px;
    }

    .dark-shadow {
        box-shadow: #202731 0px 1px 9px 2px;
    }

    .headerTableCell {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .headerLabel {
        font-family: "Krub Medium", "Krub";
        font-weight: 600;
        font-style: normal;
        font-size: 13px;
        // color: #4265ed;
        // color: rgba(51, 48, 60, 0.87);
    }

    .MuiToolbar-regular {
        min-height: 50px;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
    }

    .sortButton {
        display: flex;
        flex-direction: column;
        margin-left: 5px;

        .down {
            font-size: 20px !important;

            &:nth-of-type(1) {
                transform: rotate(180deg);
                margin-bottom: -10px;
            }
        }
    }

    & .MuiIconButton-colorSecondary {
        color: #979797;
    }

    & .Mui-selected {
        background: rgba(77, 91, 216, 0.1) !important;
    }

    & .MuiCheckbox-colorSecondary.Mui-checked {
        color: #4d5bd8;
    }

    & .noDate {
        font-family: "Krub";
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        height: 300px;
        text-align: center;
    }

    .loadingBody {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
        z-index: 99;
        border-radius: 7px;

        .tableRow {
            display: flex;
            width: 100%;
            height: 100%;
        }

        .tableCell {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 100vw !important;
        }
    }

    .table {
        box-sizing: border-box;
        flex: 1;
        // min-width: 750px;
        border-spacing: 0px;
        border-collapse: collapse;
        width: 100%;
        display: table;
        // min-width: 750px;
        // height: 100%;
    }

    .tableBody {
        // flex: 1;
        .collapse-class {
            width: 100%;
            max-height: 450px;
            min-height: 250px;
            overflow: auto;
        }
    }

    .tableContainer {
        display: flex;
        box-sizing: border-box;
        flex: 1;
        width: 100%;
        // max-height: 80vh;
        overflow: auto;
    }

    .table-row-features {
        display: flex;
        // justify-content: flex-end;
    }
}

// .table-card {
//     margin: 15px;
//     border-radius: 8px;
//     box-shadow: rgb(51 48 60 / 3%) 0px 3px 9px 1px, rgb(51 48 60 / 2%) 0px 8px 9px 0px, rgb(51 48 60 / 1%) 0px 1px 6px 4px;
//     background-color: #ffffff;
//     .MUIData-Grid-Table {
//         position: relative;
//         height: 500px;
//         width: '100%';
//         .export-button {
//             border:1px solid rgba(115, 103, 240, 0.5);
//         }
//     }
// }
