.dialog-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-title-box {
        display: flex;
        align-items: center;
    }

    .header-title {
        font-size: 18px;
        font-weight: bold;
    }

    .header-close-icon {
        font-size: 26px;
        // color: #000;
        // cursor: pointer;
    }

    .header-action-box {
        display: flex;
        align-items: center;
    }

    .line {
        border-left: 2px solid #e8e8e8cc;
        height: 30px;
        margin-left: 1rem;
        margin-right: 8px;
        border-radius: 20px;
    }
}

.dialog-footer {
    margin-right: 10px;

    .dialog-footer-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .button-group {
            margin-left: 10px;
        }
    }
}

.MuiDialog-container .MuiDialog-paper {
    overflow-y: unset;
}