.search-box {

    border-radius: 6px;
    display: flex;
    align-items: center;

    max-width: 220px;
    &>.MuiInputBase {
        padding-left: 5px;
    }

    .searchInput {
        outline: none;
        border-radius: 10px;

        // padding: 0;
        &>.MuiInputBase-root {
            padding-left: 5px;
        }

        .searchIconBtn {
            cursor: pointer;
            border-radius: 50%;
            display: flex;
            align-items: center;
            // margin: 0 auto;
            // min-width: 35px !important;
        }
    }

    .search-back-box {
        margin: 0 10px;
        border-radius: 6px;
    }

    .timePicker {
        .MuiFormControl-root {
            width: 200px;
            /* height: 0;
            border-radius: 10px;
            color: #000; */
        }
    }

    .box-shadow-search {
        // box-shadow: 0px 3px 5px -3px rgba(0, 0, 0, 0.1), 0px 0px 10px 0px rgba(0, 0, 0, 0.08),
        //     0px 3px 10px 1px rgba(0, 0, 0, 0.07);
    }

    .clear-button {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: none;

        svg {
            font-size: 1.25rem;
        }
    }

    &.allow-clear:hover {
        .clear-button {
            display: inline-flex;
            align-items: center;
        }

        .MuiSelect-icon {
            display: none;
        }
    }
}