
.logTableCell {
	display: flex;
	max-width: 300px;
}


.ellipsisBox {
	display: inline-flex;
	width: 100%;

	.ellipsisTitle {
		max-width: 150px;
		// width: 110px;
		overflow: hidden;
		white-space: nowrap;
		margin-right: 3px;
		text-align: left;
	}

	.ellipsisBtn {
		max-width: 20px;
		min-width: 15px;
		// width: 20px;
		height: 22px;
		padding: 2px 12px;
		line-height: 10px;
		background-color: #888888;
	}
}
