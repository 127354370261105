.top-bar::after {
    top: 0px;
    left: 0px;
    z-index: -1;
    width: 100%;
    content: "";
    position: absolute;
    backdrop-filter: blur(10px);
    height: calc(64px + 1rem);
    mask: linear-gradient(rgb(248, 247, 250), rgb(248, 247, 250) 18%, transparent 100%);
    // background: linear-gradient(rgba(248, 247, 250, 0.7) 44%, rgba(248, 247, 250, 0.43) 73%, rgba(248, 247, 250, 0));
}

.tool-bar {
    box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px;
}