.account {
    display: flex;
    flex-direction: column;
    position: relative;

    .phone-country {
        display: flex;
        position: absolute;
        z-index: 3;
        top: 10px;
        left: 5px;
    }

    .account-input {
        input {
            padding-left: 50px;
        }
    }
}