.loginPage {
	display: flex;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
    position: relative;
    justify-content: center;
    align-items: center;

    .controll-panel {
        position: relative;
        width: 400px;
        border-radius: 5px;
        height: 620px;
        justify-content: center;
        align-items: center;
        background-color: white;
        .controll-panel-content {
            z-index: 2;
            height: 100%;
            display: flex;
            position: relative;
            .title-box {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                .title-box-1 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .title_1 {
                        margin-bottom: 0.375rem;
                        font-weight: bold;
                        font-size: 2.0rem;
                        line-height: 1.385;
                        font-family: "Public Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
                            "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
                    }
                }
                .title_2 {
                    margin-top: 3.075rem;
                    margin-bottom: 0.375rem;
                    font-weight: 500;
                    font-size: 1.625rem;
                    line-height: 1.385;
                    
                }
                .title_3 {
                    opacity: .6;
                }
            }
    
            .login-button {
                height: 50px;
                display: flex;
                border-radius: 10px;
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
            }
    
            .login-button:disabled {
                // animation: shrink 0.2s ease-in forwards;
            }
            .authorText {
                font-size: 10px;
                color: gray;
                width: 400px;
                display: flex;
                text-align: center;
                justify-content: center;
                width: 100%;
            }

            .tail-box {
                display: flex;
                justify-content: center;
                align-items: center;
                .title_4 {

                }
                .title_5 {
                    color: blue;
                    cursor: pointer;
                    margin-left: 5px;
                }
            }
        }
      
    }

    @media (min-width: 900px) {
        .controll-panel::before {
            height: 100%;
            display: flex;
            top: -70px;
            content: "";
            left: -46px;
            width: 238px;
            height: 234px;
            position: absolute;
            background-image: url("data:image/svg+xml,%3Csvg width='239' height='234' viewBox='0 0 239 234' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='88.5605' y='0.700195' width='149' height='149' rx='19.5' stroke='%237367F0' stroke-opacity='0.16'/%3E%3Crect x='0.621094' y='33.761' width='200' height='200' rx='10' fill='%237367F0' fill-opacity='0.08'/%3E%3C/svg%3E");
        }

        .controll-panel::after {
            content: "";
            width: 180px;
            right: -57px;
            height: 180px;
            bottom: -66px;
            position: absolute;
            background-image: url("data:image/svg+xml,%3Csvg width='181' height='181' viewBox='0 0 181 181' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.30469' y='1.44312' width='178' height='178' rx='19' stroke='%237367F0' stroke-opacity='0.16' stroke-width='2' stroke-dasharray='8 8'/%3E%3Crect x='22.8047' y='22.9431' width='135' height='135' rx='10' fill='%237367F0' fill-opacity='0.08'/%3E%3C/svg%3E");
        }
    }
}

