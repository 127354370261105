.registerPage {
	display: flex;
	min-height: 100vh;
	height: 100%;
	overflow-x: hidden;
	position: relative;
	justify-content: center;
	align-items: center;

	.controll-panel {
		position: relative;
		width: 400px;
		border-radius: 5px;
		height: 620px;
		justify-content: center;
		align-items: center;
		background-color: white;

		.title-box {
			.title-box-login {
				display: flex;
				justify-content: flex-end;

				.text_1 {
					color: lightgrey;
					font-size: 14px;
				}

				.text_2 {
					font-size: 14px;
					color: blue;
					cursor: pointer;
					margin-left: 5px;
				}
			}

			.text_3 {
				margin-top: 3.075rem;
				margin-bottom: 1.6rem;
				font-weight: 600;
				font-size: 1.625rem;
				line-height: 1.385;
			}
		}

		.login-button {
			height: 50px !important;
			width: 100%;
			display: flex;
			border-radius: 10px;
			margin-top: 1.5rem;
			margin-bottom: 1.5rem;
		}

	}
}