
.setting_box {
	.setting_update_cycle {
		width: 400px;
	}
	.setting_update_gyb {
		width: 450px;
		margin-top: 20px;
	}
	
	.setting_invoice_it {
		width: 500px;
		margin-top: 20px;
	}
	.item_invoice {
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		border: none;  
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);  
		border-radius: 5px;
		margin: 5px;
		padding: 5px;
		.drop_box {
			display: inline-flex;
			justify-content: space-between;
			align-items: center;
			.drop {
				display: grid;
				.drop_up {
					color: #556cd6;
					cursor: pointer;
				}
				.drop_down {
					color: #556cd6;
					cursor: pointer;
				}
			}
		}
	}
}
