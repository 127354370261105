.react-datepicker-popper {
    z-index: 101 !important;
    .react-datepicker {
        color: #33303cde;
        border-radius: 6px;
        font-family:
            "Public Sans",
            sans-serif,
            -apple-system,
            BlinkMacSystemFont,
            "Segoe UI",
            Roboto,
            "Helvetica Neue",
            Arial,
            sans-serif,
            "Apple Color Emoji",
            "Segoe UI Emoji",
            "Segoe UI Symbol";
        background-color: #ffffff;
        box-shadow:
            #33303c08 0px 4px 10px 2px,
            #33303c05 0px 9px 10px 1px,
            #33303c03 0px 2px 7px 4px;
        border: none;
        & > .react-datepicker__navigation.react-datepicker__navigation--previous {
            width: 26px;
            height: 26px;
            border: none;
            left: 15px;
        }
        & > .react-datepicker__navigation.react-datepicker__navigation--next {
            width: 26px;
            height: 26px;
            border: none;
            right: 15px;
        }
        & > .react-datepicker__navigation {
            top: 18px;
            border-radius: 50%;
            background-color: #33303c14;
        }
        .react-datepicker__month-container {
            padding-top: 0.8rem;
        }
        .react-datepicker__header {
            padding: 0px;
            border: none;
            font-weight: normal;
            background-color: inherit;
        }
        .react-datepicker__current-month,
        .react-datepicker-year-header {
            line-height: 2.4;
            font-size: 1rem;
            font-weight: normal;
            letter-spacing: 0.15px;
            margin-bottom: 0.75rem;
            color: inherit;
        }
        .react-datepicker__day-names {
            margin-bottom: -8px;
        }
        .react-datepicker__day-names,
        .react-datepicker__week {
            white-space: nowrap;
        }
        .react-datepicker__day-name {
            margin: 0px;
            line-height: 1.5;
            width: 2.25rem;
            font-size: 0.75rem;
            letter-spacing: 0.4px;
            color: inherit;
        }
        .react-datepicker__day--today:not(.react-datepicker__day--selected):not(:empty) {
            line-height: 2.125rem;
            color: #7367f0;
            border: 1px solid #7367f0;
        }
        .react-datepicker__header:not(.react-datepicker-year-header) + .react-datepicker__month,
        .react-datepicker__header:not(.react-datepicker-year-header) + .react-datepicker__year {
            margin: 1.5rem 0.8rem 0.8rem;
        }
        .react-datepicker__day.react-datepicker__day--outside-month,
        .react-datepicker__day.react-datepicker__day--disabled:not(.react-datepicker__day--selected) {
            color: #33303c61;
        }

        .react-datepicker .react-datepicker__day--today {
            font-weight: normal;
        }
        .react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--in-range {
            box-shadow:
                #33303c08 0px 3px 5px 2px,
                #33303c05 0px 3px 5px 0px,
                #33303c03 0px 1px 4px 2px;
            border-radius: 0 !important;
            border-top-right-radius: 50% !important;
            border-bottom-right-radius: 50% !important;
            color: #ffffff !important;
            background-color: #7367f0 !important;
            line-height: 2.25rem;
        }
        .react-datepicker__day:hover,
        .react-datepicker__month-text:hover,
        .react-datepicker__quarter-text:hover,
        .react-datepicker__year-text:hover {
            background-color: #dedede33;
        }
        .react-datepicker__day {
            margin: 0px;
            width: 2.25rem;
            border-radius: 50%;
            line-height: 2.25rem;
            color: inherit;
            &:focus,
            &:active {
                outline: 0;
            }
        }

        .react-datepicker__day--in-range,
        .react-datepicker__day--in-selecting-range {
            border-radius: 0px;
            color: #7367f0;
            background-color: #7367f00f !important;
        }

        .react-datepicker__day--selected,
        .react-datepicker__month--selected,
        .react-datepicker__year-text--selected,
        .react-datepicker__quarter--selected {
            box-shadow:
                #33303c08 0px 3px 5px 2px,
                #33303c05 0px 3px 5px 0px,
                #33303c03 0px 1px 4px 2px;
            color: #ffffff !important;
            background-color: #7367f0 !important;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
        }
        .react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start,
        .react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--range-start.react-datepicker__day--in-range,
        .react-datepicker__day.react-datepicker__day--range-start {
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            color: #ffffff !important;
            background-color: #7367f0 !important;
            line-height: 2.25rem !important;
            border: unset !important;
        }

        .react-datepicker__day--keyboard-selected:not(.react-datepicker__day--in-range) {
            background-color: #33303c0f;
        }
        .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
            margin-bottom: 12px;
        }
        .react-datepicker__navigation-icon {
            position: relative;
            top: 1px;
            font-size: 20px;
            width: 0;
        }
        .react-datepicker__navigation-icon--previous {
            right: 0;
        }
        .react-datepicker__navigation-icon--next {
            left: 0;
        }
        .react-datepicker__triangle::before {
            border-bottom-color: #f0f0f0;
        }
        .react-datepicker__time-container .react-datepicker__time-list-item {
            line-height: 1.75;
            margin-left: 0.8rem;
            margin-right: 0.3rem;
            border-radius: 6px;
            height: auto !important;
            &:hover {
                background-color: #dedede33 !important;
            }
        }

        .react-datepicker__time-container
            .react-datepicker__time
            .react-datepicker__time-box
            ul.react-datepicker__time-list
            li.react-datepicker__time-list-item--selected {
            background-color: #7367f0 !important;
        }
        & > .react-datepicker__navigation.react-datepicker__navigation--next--with-time {
            right: 100px;
        }
        .react-datepicker__time-container {
            padding: 0.3rem 0rem;
        }
        .react-datepicker__time-container .react-datepicker-time__header {
            font-size: 1rem;
            line-height: 1.31;
            font-weight: normal;
            letter-spacing: 0.15px;
            margin-bottom: 0.75rem;
            color: inherit;
        }
        .react-datepicker__time-container .react-datepicker__time {
            background: inherit;
        }
        .react-datepicker__month-read-view,
        .react-datepicker__month-year-read-view,
        .react-datepicker__year-read-view {
            border: 1px solid transparent;
            border-radius: 0.3rem;
            position: relative;
        }

        .react-datepicker__header__dropdown {
            .react-datepicker__month-dropdown-container,
            .react-datepicker__year-dropdown-container {
                margin-bottom: 1rem;
            }
            .react-datepicker__month-dropdown-container:not(:last-child) {
                margin-right: 2rem;
            }
            .react-datepicker__month-dropdown,
            .react-datepicker__year-dropdown {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                border-color: #33303c1f;
                border-radius: 6px;
                background-color: #ffffff;
                box-shadow:
                    #33303c08 0px 5px 11px 3px,
                    #33303c05 0px 8px 11px 1px,
                    #33303c03 0px 3px 8px 4px;
            }
            .react-datepicker__month-option,
            .react-datepicker__year-option {
                width: auto;
                margin: 0rem 0.875rem;
                padding-top: 0.2rem;
                padding-bottom: 0.2rem;
                border-radius: 6px !important;
                &:hover {
                    background-color: #dedede33;
                }
            }
            .react-datepicker__month-dropdown {
                .react-datepicker__month-option--selected_month {
                    color: #ffffff;
                    background-color: #7367f0;
                    .react-datepicker__month-option--selected {
                        display: none;
                    }
                }
            }

            .react-datepicker__year-dropdown {
                .react-datepicker__navigation--years-upcoming {
                    width: 9px;
                    height: 9px;
                    border-style: solid;
                    border-width: 3px 3px 0px 0px;
                    transform: rotate(-45deg);
                    border-top-color: inherit;
                    border-right-color: inherit;
                    margin: 0.6875rem auto 0rem;
                }

                .react-datepicker__navigation--years-previous {
                    width: 9px;
                    height: 9px;
                    border-style: solid;
                    border-width: 0px 0px 3px 3px;
                    transform: rotate(-45deg);
                    border-left-color: inherit;
                    border-bottom-color: inherit;
                    margin: 0rem auto 0.6875rem;
                }

                .react-datepicker__year-option--selected_year {
                    color: #ffffff;
                    background-color: #7367f0;
                    .react-datepicker__year-option--selected {
                        display: none;
                    }
                }
            }
        }
        .react-datepicker__today-button {
            border-top: 0px;
            border-radius: 1rem;
            margin: 0rem 1rem 1rem;
            color: #ffffff;
            background-color: #7367f0;
        }
    }
    .react-datepicker:not(.react-datepicker--time-only) {
        .react-datepicker__time-container {
            border-left-color: #33303c1f;
        }
        .react-datepicker-time__header {
            padding-top: 0.8rem;
        }
    }
}

.time-range-box {
    .react-datepicker-wrapper {
        max-width: 100px;
    }
}
.react-datepicker-wrapper {
    width: 100%;
}
