.country-select-input {
    display: flex;
    flex-direction: column;
    position: relative;

    .country-select {
        display: flex;
        position: absolute;
        z-index: 99;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        .flag-dropdown {
            border-radius: 4px;
        }
    }

    .code-input {
        .MuiTextField-root {

        }
        input {
            padding-left: 50px;
        }
    }

    .select-option {
        margin: 5;
        display: 'flex';
        align-items: 'center';
        background-color: aqua;
    }

    .react-tel-input .country-list {
        max-height: 180px;
    }
}